.btn {
	font-family: inherit;
	font-weight: bold;
	cursor: pointer;
	text-transform: uppercase;
	display: block;
	font-size: inherit;
	filter: brightness(1);
	transition: filter 0.2s ease-in-out;
}
.btn.primair {
	background: rgba(24, 29, 27, 1);
	color: white;
	padding: 1rem 4rem;
	border: 2px solid #045de9;
	border-radius: 4px;
}

.btn.secondair {
	background-color: #045de9;
	/* background-image: linear-gradient(315deg, #045de9 0%, #09c6f9 74%); */
	color: white;
	padding: 1rem 4rem;
	border: 2px solid #045de9;
	border-radius: 4px;
}
.btn:hover {
	filter: brightness(1.2);
	transition: filter 0.2s ease-in-out;
}
