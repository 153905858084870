/* @import 'scss/mixins.css'; */
/* @import 'scss/libary.css'; */

@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@400;500;600;700&display=swap');

/* custom fonts */
/* @font-face {
 	font-family: 'Quick-Kiss';
 	src: url('./scss/font/Quick-Kiss.eot') format('truetype'),
 		url('./scss/font/Quick-Kiss.woff2') format('woff'),
 		url('./scss/font/Quick-Kiss.otf'),
 		url('./scss/font/Quick-Kiss.woff') format('woff'),
 		url('./scss/font/Quick-Kiss.svg#QuickKiss') format(svg);
 } */

* {
	margin: 0;
	padding: 0;
}
::selection {
	/* background: $pink; */
}
html {
	font-size: 62.5%;
	font-family: 'Quicksand', sans-serif;
	color: white;
	background: rgba(24, 29, 27, 1);
}
body {
	font-size: 1.4rem;
	background: rgba(24, 29, 27, 1);
}
.slide-in-left-enter {
	transform: translateX(-100%);
}

.slide-in-left-enter-active {
	transform: translateX(0);
	opacity: 1;
	transition: all 200ms;
}

.slide-in-left-exit {
	transform: translateX(0%);
	opacity: 1;
}

.slide-in-left-exit-active {
	transform: translateX(-100%);
	opacity: 0;
	transition: all 200ms;
}

.title__fullwidth::after {
	content: '\00a0';
	display: block;
	height: 4px;
	background-color: #045de9;
	background-image: linear-gradient(315deg, #045de9 0%, #09c6f9 74%);
}
.modal-enter {
	transform: scale(0.8);
	opacity: 0;
	z-index: -1;
}

.modal-enter-active {
	transform: scale(1);
	opacity: 1;
	z-index: 15;
	transition: all 200ms;
}

.modal-exit {
	transform: scale(1);
	opacity: 1;
	z-index: 15;
}

.modal-exit-active {
	transform: scale(0.8);
	opacity: 0;
	z-index: -1;
	transition: all 200ms;
}

.card {
	width: calc(49% - 6rem);
	margin: 1rem;
	padding: 2rem;
	display: flex;
	flex-direction: column;
	border: 1px solid white;
}
.card__title {
	text-align: center;
	font-size: 2rem;
	line-height: 1.5;
	margin-bottom: 1.4rem;
}
.card__list {
	list-style-type: none;
}
.card__listItem {
	padding: 0.5rem;
}
.card__listItem svg {
	color: #045de9;
}
.card__listItem + .card__listItem {
	border-top: 1px solid white;
}

.card__price {
	font-size: 2.4rem;
	text-align: center;
	font-weight: bold;
	margin-bottom: 1.4rem;
}
.card__CTA {
	margin: 1rem auto;
}
.card__CTA .btn.secondair {
	padding: 1rem 0.5rem;
}

@media (min-width: 600px) {
	.inline-grid__desktop {
		display: grid;
		grid-auto-flow: column;
		grid-auto-columns: 1fr;
		grid-auto-rows: 1fr;
	}
	.inline-grid__desktop > * {
		align-self: stretch;
	}
	body {
		font-size: 2rem;
	}
	.card__CTA .btn.secondair {
		padding: 1rem 4rem;
	}
}
