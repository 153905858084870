.activation-wrapper {
	max-width: 900px;
	margin: auto;
	position: relative;
	z-index: 6;
	width: 100%;
}
.activation-container {
	background-color: #045de9;
	background-image: linear-gradient(315deg, #045de9 0%, #09c6f9 74%);
	padding: 3px;
}
.activation-container button {
	margin: 2.4rem auto 0;
}
.activation-content {
	background: rgba(24, 29, 27, 1);
	padding: 2rem 2.4rem;
}
@media (min-width: 600px) {
	.activation-wrapper {
		margin: 2.4rem auto;
	}
	.activation-container {
		width: 800px;
	}
	.activation-content {
		display: flex;
		align-items: baseline;
		justify-content: space-evenly;
	}
	.activation-container button {
		margin: 0;
	}
}
