.steps-viewport {
	width: calc(100vw - 4.8rem);
	overflow: hidden;
	overflow-x: scroll;
}
.steps-container {
	display: inline-flex;
	width: auto;
	background: linear-gradient(315deg, #045de9 0%, #09c6f9 74%);
	background-size: 100% 50px;
	background-repeat: no-repeat;
}
.steps-container .step-container:first-child .step__header .step__counter {
	background: url('../../../public/SMALL/counter-background-border.svg');
	background-position: 0 1px;
}
.steps-container .step-container:last-child .step__header .step__counter {
	background: none;
}
.steps-container
	.step-container:last-child
	.step__header
	.step__counter::before {
	content: '';
	position: absolute;
	width: 100%;
	height: 100%;
	background: url('../../../public/SMALL/counter-background-border.svg');
	background-position: 0 1px;
	transform: rotate(180deg);
}
.steps-container .step-container:first-child .step__header .line:first-of-type {
	background: rgba(24, 29, 27, 1);
}
.steps-container .step-container:last-child .step__header .line:last-of-type {
	background: rgba(24, 29, 27, 1);
}

.step-container {
	min-width: 90vw;
}
.step__header {
	display: flex;
	height: 5rem;
}
.step__header .line {
	width: 50%;
	flex-grow: 2;
	position: relative;
}
.step__header .line::before,
.step__header .line::after {
	content: '\00a0';
	display: block;
	background: rgba(24, 29, 27, 1);
	position: absolute;
	width: 100%;
	height: 21px;
}
.step__header .line::before {
	top: 0;
}
.step__header .line::after {
	bottom: 0;
}
.step__counter {
	min-width: 6rem;
	display: flex;
	align-items: center;
	font-weight: bold;
	background: url('../../../public/SMALL/counter-background.svg');
	justify-content: center;
	background-size: cover;
	background-position: 0 0;
	position: relative;
}
.step__counter p {
	font-size: 2rem;
}
.step__content {
	margin: 1rem;
}
.step__content h3 {
	text-align: center;
	font-size: 2rem;
	height: 5.4rem;
}
@media (min-width: 600px) {
	.steps-viewport {
		width: 100%;
		overflow: hidden;
		overflow-x: scroll;
	}
	.step-container {
		min-width: auto;
		width: 45%;
	}
}
