.main-navigation__menu-btn {
	width: 3rem;
	height: 3rem;
	background: transparent;
	border: none;
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	margin-right: 2rem;
	cursor: pointer;
}

.main-navigation__menu-btn span {
	display: block;
	width: 3rem;
	height: 2.5px;
	background: white;
}

.side-drawer {
	position: fixed;
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 15;
	width: 100vw;
	height: 100vh;
}

.main-navigation__title {
	color: white;
	filter: invert(100%);
	width: calc(70vw - 6rem);
	min-width: 220px;
}

.main-navigation__title a {
	text-decoration: none;
	color: white;
}

.main-navigation__header-nav {
	display: none;
}

.main-header {
	display: flex;
	align-items: center;
	padding: 2rem;
	justify-content: space-between;
	position: fixed;
	z-index: 40;
	width: calc(100% - 4rem);
	background: rgb(24, 29, 27);
	background: linear-gradient(
		180deg,
		rgba(24, 29, 27, 1) 0%,
		rgba(24, 39, 27, 0) 100%
	);
}
.main-header-wrapper {
	position: relative;
	height: 7rem;
}
.main-navigation__CTA {
	display: none;
}

@media (min-width: 600px) {
	.main-header {
		align-items: baseline;
	}
	.main-header-wrapper {
		height: 8rem;
	}
	.main-navigation__menu-btn {
		display: none;
	}

	.main-navigation__header-nav {
		display: block;
	}

	.main-navigation__title {
		width: 40rem;
		left: calc(50% - 20rem);
		position: fixed;
	}
	.main-navigation__CTA {
		display: block;
		font-size: 1.4rem;
	}
	.main-navigation__CTA .btn.primair {
		background: transparent;
	}
}
