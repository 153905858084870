.wrapper {
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	background: rgba(24, 29, 27, 1);
	position: relative;
	z-index: 6;
}

.wrapper__backgroundImage {
	background-size: cover;
	min-height: 20rem;
	background-image: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3));
}

.container {
	margin: 2rem 2.4rem;
}
.container h2 {
	font-size: 3rem;
	margin-bottom: 1.5rem;
	text-align: center;
}
.container p,
.container ul li {
	font-size: 1.4rem;
	line-height: 1.5;
}
.container p + p {
	margin-top: 1.5rem;
}

.container h2 em {
	font-weight: bold;
	font-style: normal;
	background: linear-gradient(315deg, #045de9 0%, #09c6f9 74%);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
}
.container blockquote {
	font-size: 2rem;
}
.container blockquote::before,
.container blockquote::after {
	content: '"';
}
.container .title-container__fullwidth h3 {
	font-size: 3rem;
	font-weight: bold;
	text-align: center;
}

.reported-width .container {
	width: 100%;
}

.container .packages-container {
	display: flex;
	flex-wrap: wrap;
}

@media (min-width: 600px) {
	.wrapper__backgroundImage {
		min-height: 40rem;
		background-position: center center;
	}
	.container {
		max-width: 900px;
	}
	.container h2 {
		font-size: 4.5rem;
	}
	.container p,
	.container ul li {
		font-size: 2rem;
		line-height: 1.3;
	}
	.container p + p {
		margin-top: 1.3rem;
	}
	.container .title-container__fullwidth h3 {
		font-size: 4.5rem;
	}
}
